import React, { Component } from 'react';
import { Container, Typography} from "@material-ui/core";
import MarketTab from "../components/marketCard/marketTap";
import AllItemHeroSection from "../components/allItemHero/AllItemHeroSection";

class MarketPlace extends Component {

	constructor(props) {
    	super(props);
   	 	this.state={
         account:props.account,
   		 contract:props.contract,
         data:null
       };      
 	};
	static getDerivedStateFromProps(nextProps) {
	  return {
	   contract:nextProps.contract,
	   account:nextProps.account,
	   data:nextProps.data
	  };
	 }
 
	 render() { 
       return (
		   <>
		   <AllItemHeroSection />
      	   <Container maxWidth="lg">
           	<image url></image>
        	{
          	  this.state.data?
          	  (
            	  <div>
              		
              	  <MarketTab data={this.state.data} style={{ marginTop: 10 }} /> 
            </div>
          ):
          (   <div></div>)
        }
      </Container>
		</>
    )
  }
}

export default MarketPlace;