import React from 'react';
import {getUsername} from "../utils/getUsernameFromAddress"
import './UserProfile.css';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Spinner from '../components/spinner/spinner'


class UserProfile extends React.Component
{
    constructor(props){
        super(props);

        this.state={
            account:props.account,
            contract:props.contract,
            balance:props.balance,
            ownedTokens:0,
            UserName:"Sambhav Gaur",
            UserDesignation:'',
            userBalance:null,
            data:props.data,
            spinner:false
          };
    }

    handleChange = (event) => {
        const {value,name}=event.target;
        this.setState({[name]: value})
      }

    static getDerivedStateFromProps(nextProps) 
    {
        return {
         contract:nextProps.contract,
         account:nextProps.account,
         data:nextProps.data,
         balance:nextProps.balance,
         ownedTokens:nextProps.data.length
        };
    }

    async complete_all_data()
    {
      let balance="100";
      //console.log("akjbcsdkbsd",balance);
      let username="Sambhav Gaur",designation="Anonymous";
      //username=(this.state.users.get(this.state.account))?(this.state.users.get(this.state.account).UserName):("Anonymous");
      designation="Owner";
      this.setState({userBalance:balance , UserName:username , UserDesignation:designation })
    }


    render()
    {
        if(this.state.userBalance==null)
           this.complete_all_data();
        if(this.state.userBalance===null || this.state.spinner===true)
          return (<Spinner></Spinner>)
      else
      {
         return(
          <div className="container">
          <div className="main-body">
          
                <div className="row gutters-sm">
			 	  <h1 style={{textAlign: 'left'}}>
			 	  	<span className="mw-page-title-main">Sambhav Gaur</span>
				  </h1>
			      <hr/>
			 	  <div className="vector-body">
			 	  	<div className="mw-body-content mw-content-ltr">
			        	<div className="mw-parser-output">
			 				<table className="infobox biography vcard">
			 					<tbody>
			 						<tr>
			 							<th colspan="2" className="infobox-above" style={{fontSize:'125%;', paddingTop:'0px'}}>
			 								<div className="fn" style={{display:"inline"}}>Sambhav Gaur</div>
			 							</th>
									</tr>
			 						<tr>
			 							<td colspan="2" className="infobox-image" style={{paddingTop:'0px'}}>
			 								<img alt="Sambhav Gaur.jpg" src="/images/profile_pic.jpeg" decoding="async" width="285" height="273" data-file-width="185" data-file-height="273"/>
			 								<div className="infobox-caption">Gaur in 2018</div>
			 							</td>
									</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Born</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="birthplace">April 13, 1983 Rohini New Delhi</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Education</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">IIM Calcutta<br/>PDM College of Engineering<br/>Rukmini Devi Public School</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Title</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">Google, Sankatmochan, Magician, Baba, Alpha Male, Phoenix, Statesman</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Occupation</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">IT Engineer - Innovation</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Spouse</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">Divya Gaur (m. 2011)</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Children</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">1</div></td>
			 						</tr>
			 						<tr>
			 							<th scope="row" className="infobox-label">Awards</th>
			 							<td className="infobox-data"><div style={{display:"inline"}} className="education">Eight times GOC Award Winner</div></td>
			 						</tr>
			 					</tbody>
			 				</table>
			 				<p><b>Sambhav Gaur</b>(
								<span className="unicode haudio">
									<span className="fn">
										<span className="audio">
											<a href="/images/File:sambhav_gaur.mp3" title="About this sound">
												<img alt="" src="//upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Loudspeaker.svg/11px-Loudspeaker.svg.png" decoding="async" width="11" height="11"/>
											</a>
										</span>
										<a href="/images/sambhav_gaur.mp3" className="internal" title="Sambhav Gaur.mp3">listen</a>
									</span>
								</span>) (born April 13, 1983), also known as Tapish, is a software developer who works in the IT industry. Known for his soft skills, great general knowledge, and altruistic nature, he has earned multiple titles such as "Google", "Sankatmochan", "Magician", "Baba", "Alpha Male", "Phoenix" and "Statesman". He is the recipient of several awards, including eight GOC awards.</p>
			 				<p>Gaur is feminist by nature and a great supporter of women&apos;s rights, helping them in every walk of life. Due to his charming personality and knowledge quotient, he is quite popular among girls.</p>
			 				<p>Gaur has a passion for driving. During his college days, he used to bike around 100 km per day. He is a car enthusiast. In one of the conversations, he said that his excitement goes to the next level whenever he is at the wheel.</p>
			 				<div className="thumb tleft">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Sambhav Gaur in office party" src="/images/sam_party.jpg" decoding="async" width="250" height="215" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur in office party, 2015</div>
			 					</div>
			 				</div>
			 				<p>Gaur is very well into sports. For him, the sports are not just a hobby; they have become a way of life and a part of who he is. He began to participate in organised team sports when he was in school and his love for sports just grew from there. Over the years, he played cricket, table-tennis, football, volleyball, basketball, chess, and was on the local swim team. Sports weren&apos;t just something he did; they were a part of him. It was for the love of playing the game, as well as bonding with teammates that became lifelong friends. Sports did build his character and implement valuable qualities that he continues to use in everyday life. He loves the competition and has always strived to be better. It is safe to say that his life revolved around sports.
			 				</p>
			 				<p>Gaur has a great art of storytelling. While narrating the stories, he recreates the entire scene with his words. He is able to stretch the creative imagination and attention span of the audience and make them actively involved in the story. He evokes the emotions of the audience through compelling tales of wonder, mystery, adventure, and horror.</p>
			 				<p>Gaur has made a significant contribution to shaping the careers of many individuals. He always gives the right direction, which helps people grow in their careers. During his tenure at Fidelity International, he has trained around 350+ freshers so far.</p> 
			 				<p>Gaur is a party animal who always has a joyful celebration that includes eating, drinking, singing, and dancing. He is always ready to give and to attend parties. During his early twenties, he went to all night trance clubs and rave parties. He partied for 24 to 48 hours, but he always went to work. He goes into a trance and never gets tired of it.</p>
							<p>Gaur has always been passionate about fitness. He believes in fitness and everything about it. For him, the two most important aspects of fitness are nutrition and exercise. He began going to the gym when he was 16 or 17, and he later purchased fitness equipment for his home. He set up a personal gym at his home and do the exercise regularly. He believes that fitness can bring happiness to anyone.</p>
			 				<p>Gaur must be included in "The 100 most influential people on earth."</p><br/>
			 				<h2>Early life and education</h2>
			 				<hr/>
							<div className="thumb tleft">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Sambhav Gaur receiving his IIM Degree" src="/images/sam_degree.jpg" decoding="async" width="230" height="150" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur receiving his IIM Degree in 2021</div>
			 					</div>
			 				</div>
			 				<p>Gaur was born on 13 April 1983 in Delhi to Rekha and N.K. Gaur as Sambhav. Tapish is his nick name. His mother is a housewife, and his father is a retired gazetted officer from the Delhi Jal Board. They used to stay in government housing colony.</p>
			 				<p>He studied at Rukmini Devi Public School, Delhi, and PDM College of Engineering, Bahadurgarh. After finishing high school, he enrolled in BCA but dropped out after one year to pursue B.Tech. He majored in Computer Science Engineering and has a master&apos;s degree in Blockchain from the Indian Institute of Management, Calcutta. During his school and college days, he participated in many extracurricular activities. He was actively involved in software development to earn his pocket money.</p>
							<p>He has also completed a Blockchain course from Massachusetts Institute of Technology.</p>
							<p>During his childhood, he was quite shy by nature. But as he grew up, he transformed himself into an extrovert who can talk to anyone on any topic.</p>
							<p>Gaur is reportedly an avid reader who has a deep interest in astrophysics. He bought a telescope to gaze at planetary objects at night. He keeps posting the night visuals on social media platforms.</p>
			 				<br/>
			 				<h2>Career</h2>
			 				<hr/>
			 				<p>Gaur started his career at GSpann in 2006, where he worked on the development of Intervowen products. He shifted to the US to join this organization. He showed his capability and started working on client locations. He spent 1.5 years in GSpann before leaving in December 2007. After that, he joined Interwoven, where he was involved in product development and oversaw the development of other applications as well. His time at InterVowen was brief, and he left the organisation in September 2008 to join GSpann. During his tenure at GSpann, he worked for big tech giants like Sony Electronics, Juniper, Palm, NorthWestern, AT&T, and Xperion.</p>
			 				<div className="thumb tright">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Sambhav Gaur participating in Corporate Event" src="/images/sam_play.jpg" decoding="async" width="250" height="215" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur participating in Corporate Event, 2016</div>
			 					</div>
			 				</div>
			 				<p>In November 2009, he returned to India to get married with his longtime girlfriend, Divya Khurrana. After returning to India, he joined Sapient, where he led the development team. He worked at Sapient for one year and left the organisation in October 2010. </p>

			 				<p>In November 2010, Gaur joined Bank of America, where he worked on content management tool development. Along with CMS, he also managed the CDA team, which is responsible for the bank&apos;s flat site. He worked there for around 2.5 years and left in July 2013.</p>


			 				<p>Later, he joined Fidelity International as Technical Specialist where he worked on various applications and portfolios. He led the Web CDA Support team, where he outperformed his managers and other senior members. Given his talent, management began expanding his portfolio and assigning him responsibilities for other applications such as presentation services, IWebx, and so on. FIL hosted a code-a-thon event in 2015, with 60 teams participating. Gaur also formed a team and participated in the event. As part of the event, he created a working prototype of the FIL bazaar using blockchain technology and named it "Beermonk". With his technical and presentation skills, he impressed the judging panel and won the challenge in the blockchain theme. This was a defining moment for him, and a catalyst for the recognition of blockchain developers in the organisation. After this success, Gaur decided to get expertise in blockchain technology. He studied a lot and created multiple prototypes based on different blockchains like Bitcoin, Ethereum, Hyperledger Fabric, Quorum, R3 Corda, and many more.</p>
			 
							<div className="thumb tleft">
			 					<div className="thumbinner" style={{width:"232px"}}>
			 						<img alt="Sambhav Gaur with Fidelity International CEO, Anne Richards" src="/images/sam_ane.jpg" decoding="async" width="230" height="400" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur with Fidelity International CEO, Anne Richards in 2022</div>
			 					</div>
			 				</div>

			 				<p>In 2016, Gaur was promoted to the position of technical consultant. In 2017, Gaur joined the innovation team as the only full-time resource. There were multiple challenges before him, like creating a portfolio, understanding and onboarding the business to get problem statements, creating a volunteer team for prototype development, updating his knowledge, understanding the thought process of leadership, generating new ideas, proving his capability, and many more. In the beginning, he struggled a lot, but with time, he overcame all those challenges and became the star performer. He onboarded the stakeholders from multiple business areas, got a licence for research platforms, created a team of 50+ volunteers, created multiple prototypes, many of which went into production, absorbed all interns and graduates to create prototypes, and implemented innovative ideas for events like long service awards and family day. He seeded the idea of Mindtrust, which basically created the pipeline from idea generation to the deployment of solutions in production. His proven model of mindtrust and volunteers was later adopted by the Global Innovation Team, which created the structure of the Talent Marketplace and Innovation Council. He created his own unique space in the organization, and any senior leader who visit to India always came to Innnovation Garage for a session. He got the opportunity to give sessions before Abby Johnson, Chairperson of Fidelity International, Anne Richards, CEO of the organisation, Stuart Warner, Head of Technology, Rajiv Mittal, Head of Asia-Pacific, and many more. He is the go-to person for any blocked or stuck projects. Due to his high performance, Gaur consistently got promotions in every three years.</p>

			 				<p>Gaur was promoted to Senior Technical Consultant again in April 2019. He has impressive soft skills.</p>
			 				<div className="thumb tright">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Sambhav Gaur in AI discussion" src="/images/sam_session.jpg" decoding="async" width="300" height="230" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur delivering AI in Internal Audit session, 2023</div>
			 					</div>
			 				</div>
			 				<p>In December 2020, Gaur delivered a session on "Business Analytics & Blockchain Conclave" at the School of Petroleum Management, emphasising the use of blockchain technology in business transactions.</p>

			 				<p>He was also a speaker at "World Blockchain Conclave 2021," which was a virtual live conference streamed in India, Singapore, Bangkok, Hong Kong, Dubai, and Manila time zones.</p>
			 
			 				<p>In April 2022, Gaur became an Associate Director at Fidelity International. He created a new pod, "Engineering & Tech Prototyping," in the innovation team. He has all the qualities of a great leader. He understands the human psychology and accordingly react/behave with an individual.</p>

			 				<p>Only a few people know that Gaur tried to create a startup in the medical field, but unfortunately it didn&apos;t work.</p>

			 				<p>Some of his notable work in the innovation team includes Incidence Prediction, Face recognition for long service awards, Filipa for family day, a 50+ volunteer team, Language Translation, FIL 20year journey in VR, Chaos Engineering, adoption of VR technology in FIL, blockchain based prototypes, Knowledge graph etc.</p>
							
							<p>Gaur was a speaker at International Conference of Internal Audit and delivered a session the  with co-speaker Yukti Arora on the topic of "Technology as an Enabler of Internal Audit." He shared his insights and experiences with audience. During his presentation, he discussed how emerging technology could be leveraged to improve the effectiveness and efficiency of internal audit processes. </p>
			 				<br/>
			 				<h2>Humanitarian work</h2>
			 				<hr/>
							<div className="thumb tleft">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Hand-Drawn Painting gifted to Sambhav in 2022" src="/images/sam_reward.jpg" decoding="async" width="230" height="150" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Hand-drawn painting gifted to Sambhav in 2022</div>
			 					</div>
			 				</div>
								<p>Gaur first closely witnessed the effects of a humanitarian crisis while attending an event at an underprivileged school in Gurgaon, an experience he later credited with having brought him a greater understanding of the world. Upon his return to office, Gaur contacted the CSR team at Fidelity for more information. To learn more about the conditions of students, he started participating in the events being organised in these schools. He started taking innovation sessions with students and guiding them on how to make a career in technology.</p>

								<p>In 2022, when asked what he hoped to accomplish, he stated, "Aware of the plight of these students, I think they should be commended for what they have survived, not looked down upon." He praised the Lotus Petal Foundation and FIL for their contributions for making students lives better and pledged to do everything in his power to help these students in the coming years.</p>

								<p>In recognition of his efforts (session &amp; guidance), a student awarded him a hand-drawn, beautiful painting on October 18, 2022.</p>
								<p>Gaur is against the war. In one of the discussions, he commented, "I am praying for an end to the Ukraine war. This is the only way to end the suffering and the flight from the conflict zone. It&apos;s terrifying to see children pay the price with their lives, health, and trauma."</p>
			 				<br/>
			 				<h2>Personal life</h2>
			 				<hr/>
			 				<div className="thumb tright">
			 					<div className="thumbinner" style={{width:"252px"}}>
			 						<img alt="Sambhav Gaur with his wife and son" src="/images/sam_family.jpg" decoding="async" width="250" height="180" className="thumbimage" data-file-width="413" data-file-height="356"/>
			 						<div className="thumbcaption">Gaur with his wife and son in 2017</div>
			 					</div>
			 				</div>
			 				<p>
			 					Gaur was born and brought up in Delhi. His office is in Gurgaon, and because of the long commute time, he moved to Gurgaon in 2019. He has one elder brother Samarth Gaur who is a great support to him. As he belongs to a Brahman family, he has good knowledge of Shastra and Hindu dharma. He is fond of reading novels, technical journals, magazines, etc. He is extravagant and never thinks before spending money on his loved ones.
			 				</p>
							<p>Gaur has made good friends during his school, college and office days. Some of them are Abhinav Kansal, Tarun, Kulbhushan, Dahiya, Kanika, Sneha, Ritwika, Deepali, Pooja, Saloni, Vaibhav, Chirag, Pranay etc.
			 				</p>
							<p>Gaur is going through tough times as his father is hospitalised at AIIMS, Delhi, with incubation support.
			 				</p>
			 				<p>
			 					Gaur is known for being very close to his family and wife, Divya Gaur. He has had immense love for her since his school days. He had several fights with his batchmates over his affair with her. According to him, their affair continued for several years even while he moved to the US for work. But one fine day, Divya called him and said that she would probably marry someone else if he wouldn&apos;t return to India immediately. That was a devastating moment for him, and he returned to India to marry her. For him, his wife is the sun in the solar system. They were childhood friends and are now parents to a son, Seyon Gaur, also known as Sahas Gaur. Seyon was born in Maharaj Agrasen Hospital on January 13, 2017. Divya is a physiotherapist and runs her own clinic in Gurgaon with name "Physioheal Physiotherapy". Gaur loves his wife very much and considers her to be his lifeline.
			 				</p>
	 							<br/><br/>
	 							<h2>Professional Journey</h2>
	 							<hr/>
	 							<table className="wikitable sortable jquery-tablesorter">
	 							<thead><tr>
	 							<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Year</th>
	 							<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Organisation</th>
						     	<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Role</th>
	 						
								<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Comments</th>
	 							</tr></thead>
	 							<tbody>
	 								<tr>
	 									<td>Jan 2006 - Sep 2006</td>
	 									<td>Juniper Networks</td>
	 									<td>Technical Consultant</td>
										<td></td>
	 								</tr>
	 								<tr>
	 									<td>Sep 2006 - May 2007</td>
	 									<td>Sony Electronics</td>
	 									<td>Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Jun 2007 - Aug 2007 </td>
	 									<td>Harrahs Philadelphia Casino and Racetrack</td>
	 									<td>Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Sep 2007 - Dec 2007</td>
	 									<td>Barclays</td>
	 									<td>Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Jan 2008 - Feb 2008</td>
	 									<td>Palm, Inc</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Mar 2008 - Apr 2008</td>
	 									<td>IBM</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>May 2008 - Sep 2008</td>
	 									<td>Northwestern Mutual</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Oct 2008 - Dec 2008</td>
	 									<td>AT&T Labs</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Jan 2009 - Feb 2009</td>
	 									<td>Mattel, Inc.</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Mar 2009 - Sep 2009</td>
	 									<td>Experian</td>
	 									<td>Sr. Technical Consultant</td>
										<td>Client Location</td>
	 								</tr>
	 								<tr>
	 									<td>Jan 2006 - Oct 2009</td>
	 									<td>GSPANN Technologies, Inc</td>
	 									<td>Sr. Technical Consultant</td>
										<td></td>
	 								</tr>
	 								<tr>
	 									<td>Nov 2009 - Aug 2010</td>
	 									<td>Sapient</td>
	 									<td>Sr. Associate Technology L1</td>
										<td></td>
	 								</tr>
	 								<tr>
	 									<td>Nov 2009 - Aug 2010</td>
	 									<td>FGL Sports Ltd</td>
	 									<td>Sr. Associate Technology L1</td>
										<td>Client</td>
	 								</tr>
	 								<tr>
	 									<td>Aug 2010 - May 2013</td>
	 									<td>Bank Of America</td>
	 									<td>Senior Lead Analyst</td>
										<td></td>
	 								</tr>
	 								<tr>
	 									<td>Jun 2013-Till Date</td>
	 									<td>Fidelity International</td>
	 									<td>Associate Director</td>
										<td></td>
	 								</tr>
	 							</tbody>
	 							<tfoot></tfoot>
	 						</table>
			 				<br/><br/>
			 				<h2>Accolades</h2>
			 				<hr/>
			 				<table className="wikitable sortable jquery-tablesorter">
			 					<thead><tr>
			 						<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Year</th>
			 						<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Organisation</th>
								     <th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Award</th>
			 						
									<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Category</th>
			 						<th className="headerSort" tabindex="0" role="columnheader button" title="Sort ascending">Result</th>
			 					</tr></thead>
			 					<tbody>
			 						<tr>
			 							<td>2017</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Going the Extra Mile Award - Interns Mentoring</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
			 						</tr>
			 						<tr>
			 							<td>2018</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Outstanding Commitment</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2019</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Outstanding Commitment</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2020</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Unlocking The Values</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2021</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Making it Real - Translation Innovation</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2021</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>People Star - Innnovation Interns</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2022</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>Making it Real - Innovation Hackathon</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 						<tr>
			 							<td>2022</td>
			 							<td><a href="https://www.fidelity.co.in/" title="Fidelity International GOC Awards">Fidelity International</a></td>
			 							<td>GOC Award</td>
										<td>People Star - Early Careers Selection</td>
			 							<td className="wonClass yes table-yes2 notheme">Won</td>
				 					</tr>
			 					</tbody>
			 					<tfoot></tfoot>
			 				</table>
			 			</div>
			        </div>
			      </div>
                  
                </div>
      
              </div>
          </div>

         )
      }
         
    }
}


export default (UserProfile);
