import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Accordion } from "react-bootstrap";

import './OwnerComponent.styles.scss'

class OwnerComponent extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state={
            data:props.data,
            sellingprice: 'NOT FOR SALE',
            account:props.account,
            contract:props.contract
        }
    }


  static getDerivedStateFromProps(nextProps) {
    let Data=null;
    if(nextProps.data!=null)
      Data=nextProps.data
    return {
     contract:nextProps.contract,
     account:nextProps.account,
     data:Data
    };
}

    render()
    {
        return(
            <div className='main_form'>
                 <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Not For Sale</Accordion.Header>
                        <Accordion.Body>
                        
                        </Accordion.Body>
                      </Accordion.Item>
                      
                    </Accordion>

            </div> 
        )
    }
}

export default OwnerComponent