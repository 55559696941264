import React ,{useEffect} from "react";
import { withStyles, makeStyles, createTheme } from "@material-ui/core/styles";
import {Card, CardActionArea, CardMedia, Tooltip, Typography, ThemeProvider} from "@material-ui/core/";
import { BsFillPersonFill } from "react-icons/bs";
import {BiDish} from 'react-icons/bi'
import LocalOfferSharpIcon from "@material-ui/icons/LocalOfferSharp";
import { Grid} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import axios from "axios";
import playIcon from './PlayIcon.jpeg'
import docIcon from './DocIcon.jpeg'


const theme = createTheme();

theme.typography.caption = {
  fontSize: '1.22rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
	  fontFamily: 'Open Sans, sans-serif;'
  },
};

theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '2.0rem',
  }
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    maxWidth: 345,
    borderRadius: 8,
    color: "#000000",
    maxHeight: 395,
    "&:hover": {
      boxShadow:
        "0 1px 3px rgba(255,255,255,0.12), 0 1px 3px rgba(255,255,255,0.24)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    },
  },
  media: {
    height: 220,
    width: "100%",
    borderRadius: 5,
    transition: "transform 0.15s ease-in-out",
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 1)",
    },
  },
  
  myButton: {
    color: "#00D54B",
    backgroundColor: "#080808",
    height: 23,
    position: "relative",
    top: 7,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 12,
    borderRadius: 5,
    border: "1px solid",
    borderColor: "#00D54B",
    "&:hover": {
      backgroundColor: "#00D54B",
      borderColor: "#00D54B",
      color: "#000",
    },
  },
});


const MyTooltip = withStyles((theme) => ({
  tooltip: {fontSize: 20},
}))(Tooltip);

const MarketCard = ({name, cid, index, owner, creator, designation, file_extn, file_type}) => {
  const classes = useStyles();
  
  const [state, setState] = React.useState([{
    cid1:"",
    count:0,
  }]);

  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (checked) {
      //handlechange(state.count+1);
      setState({
        ...state,
        count: state.count + 1,
      });

    } else {
      //handlechange(state.count-1);
      setState({
        ...state,
        count: state.count - 1,
      });
    }
    
    
  };


  return (
    <Card className={classes.root} variant="outlined">
      <Grid container>
        <Grid item xs>
          <CardActionArea
            disableRipple
            onClick={() => {
              //window.location.href ="http://3.108.190.137:3000/all_tokens/" +index ;
			  window.location.href ="/all_tokens/" +index ;
            }}
          >
            <Grid container>
              <CardMedia
                className={classes.media}
                image={(file_type==='music')?(playIcon):((file_type==='doc')?(docIcon):("/images/"+cid+"."+file_extn))} 
                title={name}
              />
            </Grid>
            <Grid container direction="column" style={{ marginTop: 10 }}>
              <Grid item style={{ marginLeft: 15, marginBottom: 3 }}>
                
                <Typography gutterbottom="true" variant="h5" component="h1">
                  {name}
                </Typography>
              </Grid>
              <Grid item style={{ alignSelf: "flex-start", marginLeft: 15 }}>
                
              <div style={{ textAlign: "left" }}>
                  <div>
                    <BsFillPersonFill
                      style={{
                        verticalAlign: "middle",
                        marginRight: 5,
                        fontSize: 30,
                      }}
                    />
					  <ThemeProvider theme={theme}>
                      <Typography variant="caption">
                        Creator: {creator} 
                      </Typography>
                  </ThemeProvider>
                  </div>
                </div>

                <div style={{ textAlign: "left" }}>
                  <div>
                    <BsFillPersonFill
                      style={{
                        verticalAlign: "middle",
                        marginRight: 5,
                        fontSize: 30,
                      }}
                    />
                   

                      <Typography variant="caption">
                        Designation: {designation.slice(0,5)+"..."} 
                      </Typography>
                  
                  </div>
                </div>

                <div style={{ textAlign: "left" }}>
                  <div>
                    <LocalOfferSharpIcon
                      style={{
                        verticalAlign: "middle",
                        marginRight: 5,
                        fontSize: 30,
                      }}
                    />
                    <MyTooltip title={window.web3.utils.fromWei("0")} arrow>
                      <Typography variant="caption">
					  Price: 'PRICELESS'
                      </Typography>
                    </MyTooltip>
                  </div>
                </div>

                <div style={{textAlign: "left" }}>
                  <div>
                    
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardActionArea>
         
          
          

          <div style={{
            marginLeft: "50%",
            display: 'inline-block',
          }}>
          
            
          </div>
            
         
        </Grid>
      </Grid>
    </Card>
  );
};

export default MarketCard;
