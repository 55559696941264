import React from "react";
import { Container } from "react-bootstrap";
import OwnerComponent from "../OwnerComponent/OwnerComponent.components";
import ViewerComponent from "../ViewerComponent/ViewerComponent.jsx";
import {Image } from "react-bootstrap";
import Spinner from '../spinner/spinner'
import { Accordion } from "react-bootstrap";
import './ItemPage.styles.scss';
//import ReactWebMediaPlayer from 'react-web-media-player';
import playIcon from './PlayIcon.jpeg'
import docIcon from './DocIcon.jpeg'
import addresses from '../../constants/contracts'
import ReactPlayer from "react-player";

class ItemPage extends React.Component
{
    constructor(props)
    {
     super(props);
     this.state={}
   }



  static getDerivedStateFromProps(nextProps) {
    let Data=nextProps.data;
	let creator=null;
	let owner=null,creator_designation=null , owner_designation=null,file_type=null;
	
    if(nextProps.data.length!=0)
    {
      let index=window.location.href.slice(-2);
      index=index.replace('/','');
      Data=nextProps.data[index-1];
      creator="Abhishek Sharma";
      owner="Sambhav Gaur";
      creator_designation="Creator";
      owner_designation="Owner";
      file_type=nextProps.data[index-1].file_type
    }
    
    return {
     contract:nextProps.contract,
     account:nextProps.account,
     data:Data,
     creator:creator,
     owner:owner,
     creator_designation:creator_designation,
     owner_designation:owner_designation,
	 fileType:file_type
    };
  }


  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  
  onClickUrl = (url) => {
    return () => this.openInNewTab(url)
  }


   render()
   {
	 const playAudio = () => {
		 console.log("playing audio...");
	 	document.getElementById('audio_play').play();
		return false;
	 }
			   
     if(!this.state.data.length!=0 || !this.state.owner)
      return (<Spinner></Spinner>)
     else
       return(
           <Container>
              <br/> 
              <div className='fullinfo'>
		   		
				{this.state.fileType ==="mp3" ? 
   <ReactPlayer
           url={"/images/"+this.state.data.cid+".mp3"}
           width="400px"
           height="250px"
           playing={false}
           controls={true}
         />
		   		: this.state.fileType =="mp4"  ?  
		   			<video style={{"position":"inherit", "width":"50%", "zIndex":"1"}} controls>
							<source src={"/images/"+this.state.data.cid+".mp4"} type="video/mp4" />
					</video>
		   		: (this.state.fileType =="jpeg" || this.state.fileType =="jpg" || this.state.fileType =="png" || this.state.fileType =="jfif" || this.state.fileType =="gif") ?  
		   			<Image style={{"width":"55%"}} className='image' src={"https://gateway.pinata.cloud/ipfs/"+this.state.data.cid} rounded /> 
				: <Image style={{"width":"55%"}} className='docIcon' src={docIcon} onClick={this.onClickUrl("https://gateway.pinata.cloud/ipfs/"+this.state.data.cid)} rounded />
				}
		        
                <div className='rightsideinfo'>
                  <div className='assetname'>{this.state.data.name.toUpperCase()}</div>
                  <div className='creator_and_owner'>
                      <div className='creator'>
                         <span>Creator: {this.state.creator.toUpperCase()}</span>
                      </div>
                      <div className='creator'>
                          <span>Owner: {this.state.owner.toUpperCase()}</span>
                      </div>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Description</Accordion.Header>
                      <Accordion.Body style={{"overflowY":"scroll", "textAlign": "justify", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                         {this.state.data.description}
                      </Accordion.Body>
                    </Accordion.Item>
                   
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Creator Designation</Accordion.Header>
                        <Accordion.Body style={{"overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                        {this.state.creator_designation.toUpperCase()}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Owner Designation</Accordion.Header>
                        <Accordion.Body style={{ "overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                        {this.state.owner_designation.toUpperCase()}
                        </Accordion.Body>
                      </Accordion.Item>
                  </Accordion>
						
                   <br/><br/>
                   <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Contract Address</Accordion.Header>
                        <Accordion.Body style={{"max-height":"100px" , "overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                           {addresses.NFT_CONTRACTS_ADDRESS}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Token Id</Accordion.Header>
                        <Accordion.Body style={{"max-height":"100px" , "overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                          {this.state.data.token_id}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Token Standard</Accordion.Header>
                        <Accordion.Body style={{"max-height":"100px" , "overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                          ERC - 721
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Blockchain</Accordion.Header>
                        <Accordion.Body style={{"max-height":"100px" , "overflowY":"scroll", "font-family": "Open Sans, sans-serif", "font-size":"1.75rem"}}>
                          Mainnet Network(Ethereum)
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                </div>
              </div>
              <br/><br/> <br/><br/> 

            </Container>
       )
   }
}


export default ItemPage