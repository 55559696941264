import React from 'react';
import '../../App.css';

import './HeroSection.css';

function HeroSection() {
	
  return (
	  
    <div id="home-sec" style={{"background": "url(/images/home.jpg) no-repeat center center", "padding": "0", 
		"-webkit-background-size": "cover", 
		"background-size": "cover",
		"-moz-background-size": "cover",
		"min-height": "600px"}}>
        <div className="overlay">
            <div className="container">
                <div className="col-md-8 pad-top scrollclass">
                    <h1>
                        <strong style={{"font-family": "Open Sans, sans-serif"}}>MY PRICELESS COLLECTION - SLTs</strong>
                    </h1>
                    <h6 className="home-p">
                        A platform where all my precious collections are listed in form of digital assets
                    </h6>
                </div>
                <div className="col-md-4 img-home-side ">
                    <img src="/home-side.png" className="img-responsive" />
                </div>
            </div>
        </div>

    </div>
	  
  );
}

export default HeroSection;
