import React from 'react';
import '../../App.css';

import './HeroSection.css';

function AllItemHeroSection() {
	
  return (
	  
    <div id="home-sec" style={{"background": "url(/images/Vector_2598.jpg) no-repeat center center", "padding": "0", 
		"-webkit-background-size": "cover", 
		"background-size": "cover",
		"-moz-background-size": "cover",
		"min-height": "300px"}}>
        <div className="overlay" style={{"min-height":"300px"}}>
            <div className="container">
                <div className="col-md-8 pad-top scrollclass">
                    <h1>
                        <strong style={{"font-family": "Open Sans, sans-serif"}}>Some memories are unforgettable, remaining ever vivid and heartwarming!</strong>
                    </h1>
                    <p className="home-p">
                        Sambhav Gaur
                    </p>
                </div>
               
            </div>
        </div>
    </div>
	  
  );
}

export default AllItemHeroSection;
