import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import './Footer.styles.css';

function Footer() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
	<section id="location-sec">
	    <div className="container">
	        <div className="row text-center min-set">
	            <div className="col-md-12">
	                <h2 style={{"text-align": "center"}}>CONTACT ME</h2>
	  				<br />
	  				<ul className="social">
						<li>
      						<a href="mailto:sambhavgaur@gmail.com" target="_blank" title="">
        						<span className="icon fa fa-envelope"></span>
      					  	</a>
    					</li>
			    		<li>
					      <a href="https://www.linkedin.com/in/sambhav-gaur-814a9111/" target="_blank" title="">
					        <span className="icon fa fa-linkedin"></span>
					      </a>
					    </li>
					    <li>
					      <a href="https://www.facebook.com/sambhavgaur" target="_blank" title="">
					        <span className="icon fa fa-facebook"></span>
					      </a>
					    </li>
					    <li>
					      <a href="https://instagram.com/sambhav.gaur?igshid=YmMyMTA2M2Y=" target="_blank" title="">
					        <span className="icon fa fa-instagram"></span>
					      </a>
				    	</li>
					    <li>
					      <a href="https://twitter.com/sambhavgaur" target="_blank" title="">
					        <span className="icon fa fa-twitter"></span>
					      </a>
				    	</li>
					    <li>
					      <a href="https://mobile.twitter.com/GaurSambhav" target="_blank" title="">
					        <span className="icon fa fa-twitter"></span>
					      </a>
				    	</li> 
					    <li>
					      <a href="https://wa.me/9811979494" target="_blank" title="">
					        <span className="icon fa fa-whatsapp"></span>
					      </a>
				    	</li>
				  </ul>
				  <br /><br />
	            </div>
	        </div>
	        <div className="row">
	            <div className="col-md-12 text-center">
	                <h4>Copyright © <a href="sambhavgaur.in" target="_blank" style={{"color": "#fff"}}>SambhavGaur</a>. All Rights Reserved</h4>
	                <br />
	                
	            </div>
	        </div>

	    </div>
	</section>
    </>
  );
}

export default Footer;