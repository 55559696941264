import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Accordion } from "react-bootstrap";
import './ViewerComponent.styles.scss'

class ViewerComponent extends React.Component {
    
	constructor(props)
    {
        super(props)
        this.state={
            data:props.data,
            bidprice:0,
            account:props.account,
            contract:props.contract
        }
    }


  static getDerivedStateFromProps(nextProps) {
    let Data=null;
    if(nextProps.data!=null)
      Data=nextProps.data
    return {
     contract:nextProps.contract,
     account:nextProps.account,
     data:Data
    };
  }

    render()
    {
        return(
            <div>
               <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Asset Info</Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{"fontWeight":"bolder" , "fontSize":"30px"}}>SORRY!</div>
                                     But This Item Is Only For Display Purpose
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Rules</Accordion.Header>
                                    <Accordion.Body>
                                        This Is Priceless Collection So Not For Sale
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
            </div> 
        )
    }
}

export default ViewerComponent